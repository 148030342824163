.textinput-wrapper {
  .textinput-border {
    box-sizing: border-box;
    border: 1px solid #c6cbd1;
    padding: 5px 10px;
    border-radius: 5px;
    background: white;

    &.disabled {
      background: #f4f4f4;
    }
  }

  .textinput {
    width: 100%;
  }
}