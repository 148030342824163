@import "src/scss/variable";
@import 'src/scss/responsive.scss';

.product-detail-page-wrapper {
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  
  .product-detail-page-title {
    font-weight: 700;
    font-size: 24px;
    color: $main-color;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 5px;
    
    &.secondary {
      font-size: 14px;
    }
  }
  
  .header-section {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    align-items: flex-start;
    
    .title-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  
  .product-detail-section {
    display: flex;
    width: -webkit-fill-available;
    background-color: white;
    border-radius: 10px;
    padding: 16px;
    
    .product-image {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 16px;
      
      .image-container {
        width: 120px;
        height: 120px;
      }
      
      .MuiButton-root {
        font-size: 12px;
        padding: 6px 0;
      }
    }
    
    .product-info {
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      
      .text-section {
        display: flex;
        flex-direction: column;
        gap: 8px;
        
      }
      
      .checkbox-section {
        display: flex;
        align-items: center;
        margin-top: 8px;
        
        .MuiCheckbox-root {
          padding: 0 9px 0 0;
        }
        
        .Mui-checked {
          color: $primary-color;
        }
      }
    }
  }
  
  .input-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    .image-upload-section {
      background-color: #fff;
      width: 80px;
      height: 80px;
      align-self: center;
    }
    
    .text-input-section {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 55%;
    }
  }
  
  .categories-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    margin-top: 20px;
    
    .categories-header-section {
      
    }
    
    .categories-box {
      border-radius: 10px;
      background-color: white;
      padding: 16px;
      width: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  
  .add-on-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    margin-top: 20px;
    
    .add-on-header-section {
      display: flex;
      justify-content: space-between;
      width: -webkit-fill-available;
      margin-bottom: 16px;
    }
    
    .add-on-box {
      border-radius: 10px;
      background-color: white;
      padding: 16px;
      width: -webkit-fill-available;
      
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      
      .add-on-detail {
        padding: 0;
        display: flex;
        gap: 2rem;
        justify-content: space-between;
        
        .add-on-detail-text {
          font-weight: 700;
          font-size: 16px;
          color: $main-color;
          margin-top: 0px;
          margin-bottom: 5px;
          text-align: left;
        }
        
        .action-button-section {
          display: flex;
          gap: 4px;
        }
      }
      
      .add-on-box-text {
        font-weight: 400;
        font-size: 14px;
        color: $secondary-color;
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: left;
      }
    }
    
    .no-add-ons-indicator {
      margin-top: 10px;
      font-weight: 400;
      font-size: 14px;
      color: $secondary-color;
    }
  }
}

.product-detail-footer {
  position: sticky;
  bottom: 0;
  max-width: none;
  margin: 0 auto;
  position: relative;
  @include desktop {
    max-width: 480px;
  }
  
  .button-holder {
    background-color: white;
    box-shadow: 0px -1px 25px 0px rgba(0,0,0,0.15);
    width: 100%;
    position: fixed;
    bottom: 0;
    @include desktop {
      max-width: 450px;
    }
    
    .button-section {
      padding: 10px 16px;
      display: flex;
      justify-content: space-between;
      
      .footer-button {
        padding: 8px 40px;
        border-radius: 40px;
        width: 48%;
        text-transform: capitalize;
        line-height: 16px;
        height: 45px;
      }
    }
  }
}