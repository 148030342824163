$main-color: #0A2540;
$primary-color: #008CDC;
$secondary-color: #636363;
$tertiary-color: #FF7A7A;
$background-color: #F5F6F9;
$cGray: #979797;
$cLightGray: #D3D3D3;
$cLighterGray: #8A8A8C;

$warning-color: #ffb828;
$danger-color: #E74C3C;
$success-color: #7EC480;

$link-color: #008CDC;

$cAccent: #3978EF;
$cBlack: #121212;

$grey500: #a0aec0;
$grey600: #e5e5e5;
