@import "src/scss/variable";

.product-modifier-wizard-wrapper {
    font-family: "DM Sans", sans-serif;
    display: flex;
    flex-direction: column;
    padding: 0 15px 10px;
    background-color: $background-color;
    
    .header-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        .product-modifier-wizard-title {
            font-weight: 700;
            font-size: 24px;
            color: $main-color !important;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    
    .body-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        .product-modifier-wizard-body-label {
            color: #0A2540;
        }
        
        .product-modifier-wizard-body-content {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            width: 100%;
            
            &.add-on-type {
                :not(:last-child) {
                    margin-bottom: 10px;
                }
                
                .add-on-type-button {
                    width: 100%;
                    margin-right: 0px;
                    
                    &.MuiButton-root {
                        font-family: "DM Sans", sans-serif;
                        font-weight: normal;
                        text-transform: none;
                    }
                }
            }
            
            &.add-on-name {
                .items-avail-control-checkbox-section {
                    display: flex;
                    margin-top: 10px;
                    color: #0a2540;
                    align-items: center;
                    
                    .MuiCheckbox-root {
                      padding-left: 0;
                    }
                    
                    .Mui-checked {
                      color: $primary-color;
                    }
                }
            }
            
            &.add-on-options {
                .option-row {
                    display: flex;
                    justify-content: space-between;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    
                    .name-section {
                        width: 53%;
                        
                        .modifier-name-autocomplete {
                            .MuiAutocomplete-inputRoot {
                                font-family: 'DM Sans', sans-serif;
                                padding-top: 3px;
                                padding-bottom: 3px;
                                
                                &.Mui-focused .MuiOutlinedInput-notchedOutline {
                                  border-color: #008cdc;
                                }
                            }
                        }
                    }
                    
                    .price-section {
                        width: 45%;
                        display: flex;
                    }
                }
                
                .more-add-ons-button {
                    width: fit-content;
                }
            }
            
            &.add-on-selection-range {
                .selection-range-component {
                    margin-top: 10px;
                    
                    .MuiSelect-select {
                        font-family: "DM Sans", sans-serif;
                    }
                    
                    &.MuiOutlinedInput-root {
                        &.Mui-focused .MuiOutlinedInput-notchedOutline {
                          border-color: $primary-color;
                        }
                    }
                }
            }
        }
    }
    
    .footer-section {
        margin-top: 60px;
        width: 100%;
        
        .product-modifier-wizard-footer-button {
            width: 100%;
            
            &.MuiButton-root {
                font-family: "DM Sans", sans-serif;
                font-weight: normal;
                text-transform: none;
            }
        }
    }
}

.MuiAutocomplete-option {
    font-family: 'DM Sans', sans-serif;
}