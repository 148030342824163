@import "src/scss/variable";

.order-history-page-wrapper {
    font-family: 'DM Sans', sans-serif;
    
    .main-title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
    }

    .date-range-section {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        overflow-x: auto;
        
        .each-date-range {
            font-weight: 400;
            font-size: 14px;
            color: $secondary-color !important;
            background-color: #FFFFFF;
            height: 20px;
            padding: 2px 15px;
            border: 1px solid $grey600;
            border-radius: 15px;
            white-space: nowrap;
            margin: 3px 0;
            
            &:not(:last-child) {
                margin-right: 3px;
            }
            
            &.selected {
                color: #008cdc;
                border: 1px solid #008cdc;
                background-color: #008cdc1a;
            }
        }
    }
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    .date-range-section::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .date-range-section {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    
    .dashboard-section {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        
        .dashboard-row {
            display: flex;
            justify-content: space-between;
        }
        
        .dashboard-box {
          margin-top: 2px;
          margin-bottom: 0px;
          width: 104px;
          min-height: 55px;
          background-color:white;
          padding: 10px;
          border-radius: 10px;
          
          &.selected {
            color: #008cdc;
            border: 1px solid #008cdc;
            background-color: #008cdc1a;
          }
          
          .dashboard-flex-box {
            padding: 0;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            
            .dashboard-text-large {
                font-weight: 700;
                font-size: 14px;
                margin-top: 0px;
                margin-bottom: 0px;
                color: $main-color !important;
                text-transform: capitalize;
                

                &.green {
                    color: $success-color !important;
                }
                
                &.red {
                    color: $danger-color !important;
                }
            }
            
            .dashboard-text-small {
                font-weight: 400;
                font-size: 14px;
                color: $secondary-color !important;
                margin-top: 0px;
                margin-bottom: 0px;
                overflow-wrap: anywhere;

            }
          }
        }
    }
    
    .body-section {
        margin-top: 20px;
        padding-top: 5px;
        
        .order-history-listing-box {
            margin-top: 2px;
            margin-bottom: 0px;
            background-color:white;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            gap: 2.8rem;
            
            .order-history-entry-left-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 55%;
            }
            
             .order-history-entry-right-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 45%;
            }
            
            .order-history-entry-large {
                font-family: 'DM Sans', sans-serif;
                font-weight: 700;
                font-size: 14px;
                color: $main-color !important;
                text-transform: capitalize;
                margin-top: 5px;
                margin-bottom: 5px;
                
                &.green {
                    color: $success-color !important;
                    text-align:right;
                }
                
                &.red {
                    color: $danger-color !important;
                    text-align:right;
                }
            }
            
            .order-history-entry-small {
                font-family: 'DM Sans', sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: $secondary-color !important;
                margin-top: 0px;
                margin-bottom: 0px;
                text-align:right;
                overflow-wrap: anywhere;
            }
        }
    }
}