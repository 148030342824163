@import "src/scss/variable";

.subscription-history-page-wrapper {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans", sans-serif;
    color: #0A2540;
    
    .header-section {
        display: flex;
        flex-direction: column;
        align-items: start;
        
        .title {
            font-size: 20px;
            font-weight: 700;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    
    .body-section {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        
        .subscription-info {
            background-color: #FFFFFF;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            
            .subscription-info-header {
              display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 8px 16px;
                border-bottom: 2px solid #F5F6F9;
                
                .subscription-info-title {
                    font-weight: 700;
                    font-size: 14px;
                    padding: 12px 0;
                }
            }
            
            .subscription-info-body {
                padding: 12px 16px;
                
                .each-detail {
                    display: flex;
                    justify-content: space-between;
                    
                    .each-detail-data {
                        max-width: 50%;
                        overflow-wrap: anywhere;
                        text-align: end;
                    }
                }
            }
        }
        
        .subscription-history {
            display: flex;
            flex-direction: column;
            
            .subscription-history-title {
                font-size: 14px;
                font-weight: 700;
                padding: 16px;
            }
            
            .each-transaction {
                background-color: #FFFFFF;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;
                padding: 16px;
                
                .text-section {
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    gap: 3px;
                    
                    .top-section {
                        display: flex;
                        justify-content: space-between;
                        overflow-wrap: anywhere;
                        font-size: 12px;
                        
                        .title {
                            max-width: 55%;
                            font-weight: 700;
                        }
                        
                        .amount {
                            max-width: 35%;
                            color: #E74C3C;
                            text-align: end;
                        }
                    }
                    
                    .bottom-section {
                        display: flex;
                        justify-content: space-between;
                        overflow-wrap: anywhere;
                        font-size: 10px;
                        
                        .order-number {
                            max-width: 55%;
                            color: #636363;
                        }
                        
                        .date {
                            max-width: 35%;
                            color: #636363;
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
}