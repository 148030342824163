@import "src/scss/variable";

.category-chip {
  margin: 3px;

  &.MuiChip-root {
    border-radius: 5px;
    font-family: 'DM Sans', sans-serif;
    background-color: #008CDC1A;
    color: $main-color;
  }
  
  .MuiChip-deleteIcon {
    color: $primary-color;
  }
}

.pop-up-menu-option-container {
  display: flex;
  font-family: 'DM Sans', sans-serif;

  .category-option {
    width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.autocomplete-categories-description {
  color: $secondary-color;
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
}

.categories-autocomplete {
  .MuiAutocomplete-inputRoot {
    font-family: 'DM Sans', sans-serif;
    
    &.MuiOutlinedInput-root {
      padding: 8px;
    }
    
    @media (prefers-color-scheme: dark) {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
    
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #008cdc;
    }
  }
}
