// imports
@import '../../scss/variable.scss';

.product-listing-page-wrapper {
    font-family: 'DM Sans', sans-serif;
    display: flex;
    flex-direction: column;
    
    .header-section {
      padding: 0;
      display: flex;
      flex-direction: column;
      
      .title-section {
        display: flex;
        justify-content: space-between;
        
        .main-title {
          font-weight: 700;
          font-size: 24px;
          color: $main-color !important;
          text-transform: capitalize;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        
        .header-button {
          font-family: 'DM Sans', sans-serif;
          text-transform: none;
          font-weight: 400;
          margin: 10px 0;
        } 
      }
      
      .search-bar {
        min-width: 200px;
        width: 100%;
        display: inline-block;
        background-color: #ececf2;
        box-sizing: border-box;
        padding: 0 20px;
        border-radius: 5px;
        border: none;
  
        .MuiInputBase-input {
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #425466;
            opacity: 1; /* Firefox */
          }
  
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #425466;
          }
  
          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #425466;
          }
        }
  
        .search-bar-input {
          width: calc(100% - 25px);
          font-family: 'DM Sans', sans-serif;
        }
  
        .search-bar-logo {
          .logo {
            position: relative;
            top: 7px;
            color: #c6cbd1;
          }
        }
      }
    }
    
    .body-section {
      margin-top: 10px;
    }
}