@import "src/scss/variable.scss";

.input-map-wrapper {
  width: 100%;
  position: relative;
  margin-top: 1rem;
}

.dropdown-selection-wrapper {
  position: absolute;
  background: #fff;
  z-index: 10;
  height: auto;
  width: 100%;
  text-align: left;
  max-height: 20rem;
  overflow-y: scroll;
  border-radius: 4px;
  margin-top: 2px;
  .selection-list {
    padding: 12px 20px;
    cursor: pointer;
    &:hover {
      background-color: $primary-color;
      color: white;
    }
    .selection-label {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
}
