@import "src/scss/variable";

.store-type-page-wrapper {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans", sans-serif;
    
    .header-section {
        color: #0A2540;
        font-size: 24px;
        font-weight: 700;
        
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    
    .body-section {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        
        .store-type-selection-label {
            font-family: 'DM Sans', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: $secondary-color !important;
            margin-top: 0px;
            margin-bottom: 0px;
            text-align: left;
        }
        
        .store-type-button-section {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            
            .each-store-type {
                padding: 5px;
                display: flex;
                border: 1px solid $grey600;
                background-color: #FFFFFF;
                align-items: center;
                border-radius: 10px;
                
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
                
                &.selected {
                    color: #008cdc;
                    border: 2px solid #008cdc;
                    background-color: #008cdc1a;
                }
            }
        }
    }
    
    .footer-section {
        margin-top: 40px;
        margin-bottom: 10px;
        
        .save-button {
            width: 100%;
            font-family: "DM Sans", sans-serif;
            text-transform : capitalize;
            border-radius : 60px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 40px;
            padding-right: 40px;
            
            &.MuiLoadingButton-root {
                background-color: $primary-color;
            }
            
            &.MuiLoadingButton-loading {
                background-color: #F8F8F8;
            }
        }
    }
}