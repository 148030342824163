.search-bar {
  min-width: 200px;
  width: 100%;
  display: inline-block;
  background-color: #ececf2;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 5px;
  border: none;
  
  .MuiInputBase-input {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #425466;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #425466;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #425466;
    }
  }
  
  .search-bar-input {
    width: calc(100% - 25px);
    font-family: 'DM Sans', sans-serif;
  }
  
  .search-bar-logo {
    .logo {
      position: relative;
      top: 7px;
      color: #c6cbd1;
    }
  }
}

.loading-icon {
  animation: loadRotate 1s infinite linear;
}

@keyframes loadRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}