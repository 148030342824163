@import "src/scss/variable";

.credit-history-page-wrapper {
  .header-section {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .balance-display {
      display: flex;
      flex-direction: column;
      font-family: "DM Sans", sans-serif;

      .balance-label {
        color: #636363;
        font-weight: 700;
        font-size: 14px;
      }

      .balance-value {
        color: #0a2540;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .body-section {
    margin-top: 5px;
    height: 65vh;
    overflow-y: scroll;
    border-radius: 0.5rem;

    .credit-history-listing-holder {
      margin-top: 2px;
      margin-bottom: 0px;

      .credit-history-listing-box {
        background-color: white;
        padding: 10px;

        .credit-history-entry-flex-box {
          padding: 0;
          display: flex;
          gap: 2.8rem;

          .credit-history-entry-left-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 55%;
          }

          .credit-history-entry-right-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 45%;
          }

          .credit-history-entry-large {
            font-family: "DM Sans", sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: $main-color !important;
            text-transform: capitalize;
            margin-top: 5px;
            margin-bottom: 5px;

            &.green {
              color: $success-color !important;
            }

            &.red {
              color: $danger-color !important;
            }
          }

          .credit-history-entry-small {
            font-family: "DM Sans", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: $secondary-color !important;
            margin-top: 0px;
            margin-bottom: 0px;
            overflow-wrap: anywhere;
          }
        }
      }
    }
  }
}
