 .centeronboardscreen {
    background: #F5F6F9;
    height: 93vh;
    position: relative;
 }
  
 .scrollable {
    height:130vh;
 }

 .containerwrapper {
    background: #F5F6F9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:340px;
 }
  
  .packageoffertitle {
    font-size: 22px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-bottom: 30px;
    text-align: center;
  }
  
  .packageoffertext {
    font-size: 16px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-bottom: 10px;
    text-align: center;
  }
  
  .packageofferdetailbox {
    margin-bottom: 30px;
    margin-top: 35px;
    text-align:left;
  }
  
 .packageofferdetailtitle {
    font-size: 16px;
    color: #0a2540;
    font-weight: 400;
    font-family: 'DM Sans';
    margin-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
 }
  
 .packageoffersinglebuttonbox {
    margin: auto;
    padding: 10px;
 }

 .packageofferrejectbutton {
    font-family: 'DM Sans';
    font-size: 14px;
    padding-top: 8px ;
    padding-bottom: 8px ;
    padding-left: 40px ;
    padding-right: 40px ;
    border: 1px solid #008CDC ;
    background-color: white ;
    border-radius: 40px ;
    color: #008CDC ;
    width: 100% ;
    height: 45px ;
    margin-top:0px ;
    line-height: 16px ;
    text-transform:none ;
}

.packageofferconfirmbutton {
   font-weight: 400;
   font-family: 'DM Sans';
   font-size: 14px;
   padding-top: 8px ;
   padding-bottom: 8px ;
   padding-left: 8px ;
   padding-right: 8px ;
   border: 1px solid #008CDC ;
   background-color: #008CDC ;
   border-radius: 40px ;
   color: white ;
   width: 100% ;
   height: 45px ;
   margin-top:0px ;
   line-height: 16px ;
   text-transform:none ;
}