@import "../../../scss/variable.scss";

.error-message {
  color: #cc0000;
}
.orderview-single-button-box {
  margin: auto;
  padding: 10px;
}

.on-submit-btn {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  border-radius: 40px;
  color: white;
  width: 100%;
  height: 45px;
  margin-top: 0px;
  line-height: 16px;
  text-transform: capitalize;
}
.on-submit-btn[disabled] {
  background-color: #9ca3af;
}
