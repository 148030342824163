@import '../../scss/variable.scss';

.link-qr-screen-wrapper {
    display: flex;
    flex-direction: column;
    
    .link-qr-description-label {
        color: #0A2540;
        margin: 15px 0 20px;
    }
    
    .qr-list {
        margin-top: 20px;
        
        .qr-option {
            margin-bottom: 10px;
            border-radius: 10px;
            padding: 16px;
            min-width: 64px;
            display: flex;
            align-items: center;
            
            .number-label {
                color: #0A2540;
                margin-right: 20px;
                font-size: 20px;
                font-weight: 550;
            }
            
            .domain-label {
                color: $primary-color;;
                overflow-wrap: anywhere;
            }
        }
    }
}

.confirm-dialog {
    &.MuiDialog-root {
        * {
            font-family: 'DM Sans', sans-serif;
        }
    }
}