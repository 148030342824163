@import "../../scss/variable.scss";

.dropzone {
  background: white;
  border: 1px dashed #c2c2c2;
  border-radius: 3px;
  text-align: center;
  // padding: 10px 50px;
  height: 13.5rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: gray;
  flex-direction: column;
  justify-content: center;
}

.container {
  .open-file-dialog-btn {
    border-color: #008CDC;
    background-color: #008CDC;
    color: #ffffff;
    box-shadow: none;
    font-family: "DM Sans", sans-serif;

    &:hover {
      border-color: #008CDC;
      background-color: white;
      color: #008CDC;
    }
    
    &.Mui-disabled {
      background-color: #F8F8F8;
    }
  }

  .image-preview-container {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    @media only screen and (max-width: 1440px) {
      max-width: 800px;
    }
    // width: 100%;
    width: 0px;
    min-width: 100%;
    justify-content: center;

    .img-error-msg {
      font-size: 12px;
      width: 200px;
      word-wrap: break-word;
      color: red;
    }

    .photo {
      border-radius: 5px;
      border: 1px solid #008CDC;
      // display: flex;
      align-items: center;
      text-align: center;

      .cross {
        cursor: pointer;
        position: relative;
        top: 0;
      }

      .img-wrapper {
        margin: 0 auto;
        width: 200px;
        height: 200px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        .img-functional-header,
        .img-functional-footer {
          display: none;
        }

        &:hover {
          .img-functional-header {
            display: block;
            text-align: right;

            .icon-MdCancel {
              cursor: pointer;
              color: #d32f2f;
              padding: 5px;
            }
          }

          .img-functional-footer {
            display: flex;
            flex-grow: 1;
            width: 100%;
            height: 40px;
            align-items: center;
            justify-content: center;
            color: #c6cbd1;
            background-color: gray;
            margin-top: 122px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            cursor: pointer;

            * {
              cursor: pointer;
            }

            .view {
              padding: 0 5px;
            }
          }
        }
      }

      img {
        max-height: 232px;
        max-width: 232px;
        position: relative;
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    .single-image-uploader {
      border: 1px dashed #008CDC;
      border-radius: 5px;
      min-width: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      * {
        cursor: pointer;
      }

      .plus {
        font-weight: bold;
        font-size: 100px;
        color: #008CDC;
        text-align: center;
        display: block;
      }

      .label-upload-images {
        color: #008CDC;
        text-align: center;
        display: block;
      }
    }
  }
}