@import "src/scss/variable";

.top-up-page-wrapper {
    font-family: "DM Sans", sans-serif;
    
    .header-section {
        color: #0A2540;
        font-size: 24px;
        font-weight: 700;
        
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    
    label {
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      font-size: 24px;
      color: $main-color !important;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    
    .body-section {
        height: 70vh;
        overflow-y: scroll;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        
        .top-up-input-label {
           font-family: 'DM Sans', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: $secondary-color !important;
            margin-top: 0px;
            margin-bottom: 0px;
            text-align: left;
        }
        
        .top-up-input {
            margin-top: 20px;
        }
        
        .predefined-input-button-section {
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            
            .predefined-input-button {
                font-family: "DM Sans", sans-serif;
                font-size: 14px;
                color: $secondary-color;
                border-color: $secondary-color;
                font-weight: normal;
                min-width: 10%;
                margin-right: 0px;
                
                &.MuiButton-root {
                    padding: 5px 10px;
                }
            }
        }
        
        .pay-button {
            width: 100%;
            margin-top: 20px;
            font-family: "DM Sans", sans-serif;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 40px;
            padding-right: 40px;
            height: 45px;
            text-transform : capitalize;
            border-radius : 60px;
            
            &.MuiLoadingButton-root {
                background-color: $primary-color;
            }
            
            &.MuiLoadingButton-loading {
                background-color: #F8F8F8;
            }
        }
    }
}