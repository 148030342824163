.setting-home-page-wrapper {
    .setting-menu {
        display: flex;
        flex-direction: column;
        
        .setting-menu-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            color: #0A2540;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding-top: 10px;
            padding-bottom: 10px;
            
            &.MuiButton-root {
                font-family: 'DM Sans', sans-serif;
                text-transform: none;
                padding: 0;
                background-color: transparent;
            }
        }
    }
}

.setting-main-text {
    font-family: 'DM Sans', sans-serif;
    font-size : 15px;
    font-weight : 700;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: left;
}

.setting-desc-text {
    font-family: 'DM Sans', sans-serif;
    font-size : 12px;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: left;
}