 .centeronboardscreen {
    padding-top: 56px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
 }

 .packagescreen {
  padding: 24px;
 }

 .scrollable {
    height: 130vh;
 }

 .containerwrapper {
    background: #F5F6F9;
 }
  
  .packageoffertitle {
    font-size: 22px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
  }

  .packageofferdesc {
    font-size: 18px;
    font-family: 'DM Sans';
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .packageoffertext {
    font-size: 16px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-bottom: 10px;
    text-align: center;
  }
  
  .packageofferdetailbox {
    margin-bottom: 30px;
    margin-top: 35px;
    text-align:left;
  }
  
 .packageofferdetailtitle {
    font-size: 16px;
    color: #0a2540;
    font-weight: 400;
    font-family: 'DM Sans';
    margin-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
 }
  
 .packageoffersinglebuttonbox {
    margin: auto;
    padding: 10px;
 }

 .packageofferrejectbutton {
    font-family: 'DM Sans';
    font-size: 14px;
    padding-top: 8px ;
    padding-bottom: 8px ;
    padding-left: 40px ;
    padding-right: 40px ;
    border: 1px solid #008CDC ;
    background-color: #F5F6F9 ;
    border-radius: 40px ;
    color: #008CDC ;
    width: 100% ;
    height: 45px ;
    margin-top:0px ;
    line-height: 16px ;
    text-transform:none ;
}

.packageofferconfirmbutton {
   font-weight: 400;
   font-family: 'DM Sans';
   font-size: 14px;
   padding-top: 8px ;
   padding-bottom: 8px ;
   padding-left: 8px ;
   padding-right: 8px ;
   border: 1px solid #008CDC ;
   background-color: #008CDC ;
   border-radius: 40px ;
   color: white ;
   width: 100% ;
   height: 45px ;
   margin-top:0px ;
   line-height: 16px ;
   text-transform:none ;
}
.buttonrow {
  padding: 0 16px;
}
.backbuttonrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 6px;
  color: #000;
  border-radius: 20px;

  .backbutton {
    margin: 0;
    color: #000;

    .backicon {
      font-size: 26px;
    }

    h3 {
      font-family: 'DM Sans';
      font-weight: 500;
      margin: 0;
      margin-left: 12px;
      text-transform: capitalize;
    }
  }
}
.packageswrapper {
  margin-top: 20px;

  .packagesrow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .packagescontent {
      flex: 1;
      padding: 2px 20px;

      ul {
        padding: 0 16px;
      }
    }

    .packagestrailing {
      padding: 2px 0;
    }
  }

  .termsrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}