.orderhome-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .store-status-switch-section {
        display: flex;
        
        .store-status-label {
            margin-right: 5px;
            color: #0A2540;
        }
    }
}

.each-tab {
    &.MuiTab-root {
        padding: 12px 2px;
    }
    
    .tab-label-wrapper {
        display: flex;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        text-transform: capitalize;
        align-items: center;
        
        .tab-number-display {
            background-color: #E74C3C;
            color: #FFFFFF;
            border-radius: 20px;
            padding: 2px 8px;
            margin-left: 2px;
        }
    }
}
    