@import "src/scss/variable";

.payment-method-page-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    
    .header-section {
        color: #0A2540;
        font-size: 24px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    
    .body-section {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        
        .payment-method-selection-label {
            font-family: 'DM Sans', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: $secondary-color !important;
            margin-top: 0px;
            margin-bottom: 0px;
            text-align: left;
        }
        
        .payment-method-input-label-title {
            font-family: 'DM Sans', sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: $secondary-color !important;
            margin-top: 0px;
            margin-bottom: 10px;
            text-align: left;
        }
        
        .payment-method-input-label-text {
            font-family: 'DM Sans', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: $secondary-color !important;
            margin-top: 0px;
            margin-bottom: 0px;
            
        }

        .payment-method-button-wrapper {
            border: 1px solid #d3d3d3;
            background-color: white;
            border-radius: 10px;
            padding: 10px;
        }
        
        .payment-method-button-section {
            display: flex;
            flex-direction: column;
            :not(:last-child) {
                margin-bottom: 10px;
            }
            
            .payment-method-button {
              width: 100%;
              margin-right: 0px;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 40px;
              padding-right: 40px;
              height: 45px;
              border-radius: 10px;
              border: 0;
                
                &.MuiButton-root {
                    font-family: "DM Sans", sans-serif;
                    font-weight: normal;
                    text-transform: none;
                }
            }
        }
        
        .qr-code-upload-section {
            display: flex;
            flex-direction: column;
            
            &.margin-top {
                margin-top: 20px;
            }
            
            .qr-code-label {
                color: #0A2540;
                align-self: left;
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
        
        .payment-method-input-section {
            display: flex;
            flex-direction: column;
            
            &:not(:first-child) {
                margin-top: 20px;
            }
            
            .payment-method-input-label {
                color: #0A2540;
                margin-bottom: 10px;
            }
        }
    }
    
    .footer-section {
        margin-top: 40px;
        margin-bottom: 10px;
        
        .save-button {
            width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 40px;
            padding-right: 40px;
            height: 45px;
            text-transform : capitalize;
            border-radius : 60px;
            
            &.MuiLoadingButton-root {
                background-color: $primary-color;
            }
            
            &.MuiLoadingButton-loading {
                background-color: #F8F8F8;
            }
        }
    }
}