//Fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .sign-in-root {
    // height: auto;
    // width: auto;
    // display: flex;
    // flex-direction: row;
    // width: 100%;
    .container-wrapper {
      margin-left: 25px;
      margin-right: 25px;
      text-align: center;
      // display: flex;
      // flex: 1;
      // justify-content: space-around;
      .sign-in-container {
        // overflow-x: hidden;
        height: auto;
        // padding: 170px;
        display: inline-grid;
        flex-direction: row;
        box-sizing: border-box;
        .title {
          font-size: 50px;
          color: #0a2540;
          font-family: 'DM Sans Bold';
          margin-bottom: 10px;
          text-align: center;
        }
        .subtitle {
          font-size: 20px;
          margin-left: 20px;
          color: #0a2540;
          font-family: 'DM Sans Bold';
          text-align: center;
        }
        .description {
          font-size: 24px;
          color: #0a2540;
          font-family: 'DM Sans';
          margin-bottom: 20px;
          text-align: center;
        }
        .input-main-theme {
          background-color: white;
          border-radius: 5px;
          width: 100%;
          margin-top: 10px;
        }
        .forgot-password {
          font-family: 'DM Sans Medium';
          margin: 5px;
          color: #008cdc;
          font-size: 14px;
          float: left;
          cursor: pointer;
        }
        .sign-up {
          margin-top: 20px;
          text-align: center;
        }
        .sign-in {
          width: 400px;
          height: 45px;
          border-radius: 6px;
          font-size: 15px;
          margin: 5px;
          text-transform: capitalize;
          font-family: 'DM Sans Bold';
          color: white;
          background-color: #008cdc;
        }
  
        .btn-back-to-login {
          font-weight: bold;
          border-color: #008cdc;
          width: 103px;
          color: #008cdc;
          margin-right: 10px;
          height: 45px;
          margin: 5px;
        }
  
        .Mui-focused {
          color: black !important;
        }
        .login-alert-body {
          margin-bottom: 10px;
          color: white;
          font-size: 13px;
          font-family: 'DM Sans Medium';
          text-align: left;
        }
        .login-alert-box {
          max-width: 400px;
          padding: 10px;
          border-style: solid;
          border-radius: 2px;
          border-width: 1px;
          background-color: #f3d3d9;
          color: red;
        }
        .login-success-box {
          max-width: 400px;
          padding: 10px;
          border-style: solid;
          border-radius: 2px;
          border-width: 1px;
          background-color: #f3d3d9;
          color: #4caf50;
        }
      }
    }

    .sign-in-box {
      border: 1px solid red;
    }

    .img-wrapper {
      display: flex;
      flex: 1;
      align-self: center;
      .img-placeholder {
        width: 570;
        height: 450;
        max-width: 570;
        max-height: 450;
      }
    }
    .center {
      text-align: center;
    }
    .password-requirement {
      font-size: 13px;
      border: 1px rgb(166, 213, 250) solid;
      background-color: rgb(217, 238, 255);
      ul li {
        text-align: left;
      }
    }
  }

  .container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }

  .sign-in-title {
    font-size: 50px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-bottom: 10px;
    text-align: center;
  }

  .sign-in-subtitle {
    font-size: 20px;
    margin-left: 20px;
    color: #0a2540;
    font-family: 'DM Sans';
    text-align: center;
  }

  .sign-in-description {
    font-size: 24px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sign-in-fields {
    width: 300px;
  }

  .sign-in-input-main-theme {
    background-color: white;
    border-radius: 5px;
    margin-top: 10px !important;
    width: 100%;
  }

  .sign-in-button {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    font-size: 15px;
    margin: 5px;
    text-transform: capitalize;
    font-family: 'DM Sans';
    color: white;
    background-color: #008cdc;
  }

  .btn-back-to-login {
    font-weight: bold;
    border-color: #008cdc;
    width: 100%;
    color: #008cdc;
    margin-right: 10px;
    height: 45px;
    margin-top: 5px;
  }

  .forgot-password {
    font-family: 'DM Sans';
    color: #008cdc;
    font-size: 12px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    font-weight: 400;
    letter-spacing: .25px;
    outline: 0;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    font-size: inherit;
    padding: 0;
    text-align: left;
    border: 0;
  }
  
  .forgot-password-box {
    margin-bottom: 15px;
    margin-top: 10px;
    text-align:left !important;
  }
  
  .language-select-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 10px;
  }