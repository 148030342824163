.faqs-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    
    .faq-back{
        padding-left: 16px;
        padding-right: 16px;
    }
    
    .header-section {
        padding-left: 15px;
        background-color: #FFFFFFD9;
    }
}

.faq-back {
    padding-left: 15px;
    padding-right: 15px;
}