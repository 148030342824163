.pagenotfound-box {
    padding: 24px;
}

.pagenotfound-header-title {
    margin-top:0px;
    margin-bottom:0px;
    padding-top:24px;
    font-size: 45px;
}

.pagenotfound-header-text {
     margin-top:0px;
     padding-top:10px;
     font-size: 32px;
}

.pagenotfound-text {
     font-size: 18px;
     font-weight: 400;
}