@import "src/scss/variable";

.simpler-dropzone-container {
    width: 100%;
    height: 100%;
    
    .simpler-dropzone {
        width: 120px;
        height: 120px;
        display: flex;
        
        .simpler-dropzone-label {
            align-self: center;
            text-align: center;
            color: $grey500;
        }
        
    .simpler-image-preview-container {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        height: 100%;
        justify-content: center;
        
        .image-dropzone-handler {
            display: flex;
            flex-direction: column;
        }
        
        .photo {
          // display: flex;
          align-items: center;
          text-align: center;
    
          .img-wrapper {
            margin: 0 auto;
            width: 120px;
            height: 120px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            border-radius: 10px;
    
            .img-functional-header {
              display: none;
            }
    
            &:hover {
              .img-functional-header {
                display: block;
                text-align: right;
    
                .icon-cancel {
                  cursor: pointer;
                  color: #c6cbd1;
                  padding: 5px;
                }
              }
            }
          }
        }
      }
    }
}
