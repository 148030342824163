// imports
@import '../../scss/variable.scss';

.scroll-load-list-wrapper {
  // max-height: 65vh;
  
  .product-listing-row-wrapper {
    margin-top: 2px;
    margin-bottom: 8px;
    background-color:white;
    padding: 10px;
    border-radius: 8px;
    display: flex;
      
    .product-image {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
    
    .product-detail-display {
      margin-left: 10px;
      width: 100%;
      
      .product-detail-large {
        font-weight: 700;
        font-size: 16px;
        color: $main-color !important;
        text-transform: capitalize;
        margin-top: 0px;
        margin-bottom: 5px;
        text-align: left;
      }
    }
    
    .product-detail-button-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  
  .add-on-listing-row-wrapper {
    margin-top: 2px;
    margin-bottom: 8px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .add-on-name {
      font-weight: 700;
      font-size: 16px;
      color: $main-color !important;
      text-transform: capitalize;
      text-align: left;
      max-width: 85%;
      overflow-wrap: anywhere;
    }
  }
    
  .error-handler {
    margin: 25px;
    color: $grey500;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    
    .blue-icon {
      color: $primary-color;
    }

    .refresh-button {
      background-color: $primary-color;
      color: #ffffff;
      font-weight: bold;
      text-transform: none;
      box-shadow: none;

      &:hover {
        background-color: $primary-color;
      }
    }
  }
}

.switch-wrapper {
  margin: 0px;
  display: inline;
  position: relative;
}

