.google-map-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;

  .google-map {
    width: 100%;
    height: 100%;
  }
  .map-pin-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto auto 7% auto;
    align-items: center;
    position: absolute;
    width: 80px;
    text-align: center;
    right: 0;
    left: 0;

    .pin {
      color: #0a2540;
      width: 2.2rem;
      height: 2.2rem;
    }
  }
  .no-address-wrapper {
    position: absolute;
    width: 12rem;
    color: #d32f2f;
    text-align: center;
    right: 0;
    left: 0;
    margin: auto auto 5% auto;
  }
}
.store-address-detail {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #636363 !important;
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: left;
}
