.centeronboardscreen {
    background: #F5F6F9;
    height: 93vh;
    position: relative;
}
  
.scrollable {
  height:130vh;
}

.containerwrapper {
    background: #F5F6F9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:340px;
}

  .onboardtitle {
    font-size: 28px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-bottom: 10px;
    text-align: center;
  }

  .onboardsubtitle {
    font-size: 20px;
    margin-left: 20px;
    color: #0a2540;
    font-family: 'DM Sans';
    text-align: center;
  }

  .onboarddescription {
    font-size: 24px;
    color: #0a2540;
    font-family: 'DM Sans';
    margin-bottom: 20px;
    text-align: center;
  }

  .onboardinputmaintheme {
    background-color: white;
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
  }

  .onboardbutton {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    font-size: 15px;
    margin: 5px;
    text-transform: capitalize;
    font-family: 'DM Sans';
    color: white;
    background-color: #008cdc;
  }

  .onboardingrequiredfield {
    font-family: 'DM Sans';
    color: #008cdc ;
    font-size: 12px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    font-weight: 400;
    letter-spacing: .25px;
    outline: 0;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    text-align: right;
    border: 0;
  }
