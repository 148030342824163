@import "src/scss/variable";

.button-wrapper {
  margin: 0px;
  display: inline;
  position: relative;

  .import-export-icon-btn {
    font-weight: bold;
    width: 165px;
    color: $primary-color;
    border: 2px solid $primary-color;
  }

  .outlined-btn {
    font-weight: bold;
    border-color: $primary-color;
    min-width: 103px;
    color: $primary-color;
    margin-right: 10px;

    &.delete,
    &.red {
      color: #ca3c28;
      border-color: #ca3c28;
    }

    &.nowrap {
      @media only screen and (min-width: 986px) {
        white-space: nowrap;
      }
    }

    &.float-right {
      float: right;
    }
  }

  .outlined-btn-without-border {
    font-weight: bold;
    border-color: $primary-color;
    min-width: 103px;
    color: $primary-color;
    margin-right: 10px;
    border: none;
    text-decoration: underline;

    &.delete,
    &.red {
      color: #ca3c28;
      border-color: #ca3c28;
    }

    &.nowrap {
      @media only screen and (min-width: 986px) {
        white-space: nowrap;
      }
    }
  }

  .contained-btn {
    font-weight: bold;
    border-color: $primary-color;
    background-color: $primary-color;
    box-shadow: none;
    min-width: 103px;
    text-transform : capitalize;
    border-radius : 60px;

    .MuiButton-label {
      color: white;
    }

    &:hover {
      background-color: $primary-color;
    }

    &.Mui-disabled {
      border-color: $grey500;
      background-color: $grey500;
    }
  }

  .add-icon-btn {
    font-weight: bold;
    width: 165px;
    box-shadow: none;
    background-color: $primary-color;

    &:hover {
      background-color: $primary-color;
    }
  }

  .buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;

    &.contained {
      color: white;
    }
  }

  .no-border {
    &.MuiButton-outlined {
      &.Mui-disabled {
        border: none;
      }

      &:hover {
        background-color: transparent;
      }
    }

    border: none;
    min-width: 0;
  }

  .no-margin-right {
    margin-right: 0px;
  }

  .float-right {
    float: right;
  }

  .align-label-right {
    justify-content: end;
    padding: 5px 0;
  }
}

.custom-icon-button {
  &.MuiIconButton-root {
    padding: 0;
  }
  
  &.MuiIconButton-colorPrimary {
    color: $primary-color;
  }
  
  &.MuiIconButton-colorError {
    color: #d32f2f;
  }
}

.custom-button {
  &.MuiButton-root {
    font-family: 'DM Sans', sans-serif;
    text-transform: none;
    border-radius: 4px;
    padding: 6px 16px;
  }
  
  &.MuiButton-containedPrimary {
    color: #FFFFFF;
    background-color: $primary-color;
    border-radius: 40px;
  }
  
  &.MuiButton-containedError {
    color: #FFFFFF;
    background-color: #d32f2f;;
  }
  
  &.MuiButton-outlinedPrimary {
    border: 1px solid $primary-color;
    color: $primary-color;
  }
  
  &.MuiButton-textError {
    color: #d32f2f;
  }
  
  &.MuiButton-textPrimary {
    color: $primary-color;
  }
}

.custom-loading-button {
  &.MuiLoadingButton-root {
    font-family: 'DM Sans', sans-serif;
    background-color: $primary-color;
    color: #FFFFFF;
    
    &:focus {
      background-color: $primary-color;
    }
  }
  
  &.MuiLoadingButton-loading {
    background-color: #F8F8F8;
  }
}
