// imports
@import 'src/scss/variable.scss';
@import 'src/scss/mixin.scss';
@import 'src/scss/responsive.scss';

//Fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.product-create-container {
    padding: 0 0 20px;
    background-color: $background-color;
    margin-top: 20px;
    border: 0px;
    box-shadow: none;
  
    .product-paper{
      padding: 0 20px;
    }

    .product-create-selection-section {
      margin-top: 20px;
      .selection-button {
        text-transform:capitalize;
        border-radius: 25px;
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
     
    }

    .product-create-checkbox-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0.5rem;
      margin-bottom: 20px;

      .checkbox {
        margin-right: 10px;
        margin-left: -10px;
        padding: 0px !important;
      }
    }

    .product-create-textfield-section {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right:5px;
        display: flex;
        flex-direction: column;
        .product-create-textfield {
            margin-top: 10px;
            // margin-left: 1rem;
            background-color: #fff;

            &.text {
              width: 100%;
            }

            // &.price {
            //   width: 15rem;
            // }
          }

      }
    
    .product-image-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top:20px;
      
      .img-wrapper {
        margin: 0 auto;
        width: 300px;
        height: 300px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
      }
    }
    
    .product-upload-image-box{
          padding: 0 0.5rem;
      .product-upload-image{
          margin-top: 10px;
          border: 1px solid $background-color;
          background-color: #fff;
      }
    }

    .product-dropzone {
      background: white;
      border: 1px solid #c2c2c2;
      border-radius: 3px;
      text-align: center;
      // padding: 10px 50px;
      height: 8.5rem;
      display: flex;
      align-items: center;
      font-weight: 500;
      color: gray;
      flex-direction: column;
      justify-content: center;
    }

    .product-confirm-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1.5rem 0 1rem;

      .left{
        width: 40%
      }
      .right{
        width: 60%
      }
    }

    .product-confirm-modifier-section{
      display: flex;
      flex-direction: column;
      margin: 1rem 0;
    
      .modifier-title {
        display: flex;
        align-items: center;
        
        .delete-modifier-button {
          margin-left: 10px;
        }
      }

      .modifier-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0.5rem 0 0;

        .left{
          width: 40%
        }
        .right{
          width: 60%
        }
      }

     
    }

    .confirm-section-text {
      font-family: 'DM Sans', sans-serif;
      font-size: 14px;
      font-weight: 200;
      color: $secondary-color !important;
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: left;

      &.mediumBold{
        font-weight: 500;
      }

      &.bolder{
        color: $main-color !important;
        font-weight: 700;
      }
    }
      

    .product-confirm-img{
      margin: 0 auto;
      width: 120px;
      height: 100px;
      min-width: 120px;
      min-height: 100px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px;
      position: relative;
      border: 1px solid grey;
    }

  .categories-box {
    border-radius: 10px;
    background-color: white;
    padding: 16px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
  }
}